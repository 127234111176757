<template>
  <div class="data-contain">
    <div class="affixion-contain">
      <Form :label-width="115" :model="BloodData">
        <Row>
          <Col span="8" offset="8">
            <Form-item label="日期：">
              <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
            </Form-item>
            <Form-item label="采集时间：">
              <Time-picker confirm type="time" placeholder="选择时间" @on-change="handleChangeTime"></Time-picker>
            </Form-item>
            <Form-item label="收缩压：">
              <Row>
                <Col :span="21"><Input v-model="BloodData.systolic" placeholder="请输入收缩压"></Input></Col>
                <Col :span="3">&nbsp;&nbsp;mmHg</Col>
              </Row>
            </Form-item>
            <Form-item label="舒张压：">
              <Row>
                <Col :span="21"><Input v-model="BloodData.diastolic" placeholder="请输入舒张压"></Input></Col>
                <Col :span="3">&nbsp;&nbsp;mmHg</Col>
              </Row>
            </Form-item>
            <Form-item label="心率：">
              <Row>
                <Col :span="20"><Input v-model="BloodData.heart" placeholder="请输入心率"></Input></Col>
                <Col :span="4">&nbsp;次/分</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave()">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			BloodData: {
				test_date: '',
				test_time: '',
				systolic: '',
				diastolic: '',
				heart: '',
			},
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.BloodData.member_id = this.memberId;
				MemberService.bloodAdd(this.BloodData).then(() => {
					this.handleBack();
				});
			}
		},
		handleChangeDate(value) {
			this.BloodData.test_date = value;
		},
		handleChangeTime(value) {
			this.BloodData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.BloodData.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			} else if (!this.BloodData.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			} else if (this.BloodData.systolic === '') {
				this.$Message.warning('请输入收缩压');
				return false;
			} else if (!this.isPositiveNumber(this.BloodData.systolic)) {
				this.$Message.warning('请输入收缩压有效数字');
				return false;
			} else if (this.BloodData.diastolic === '') {
				this.$Message.warning('请输入舒张压');
				return false;
			} else if (!this.isPositiveNumber(this.BloodData.diastolic)) {
				this.$Message.warning('请输入舒张压有效数字');
				return false;
			} else if (this.BloodData.heart === '') {
				this.$Message.warning('请输入心率');
				return false;
			} else if (!this.isPositiveNumber(this.BloodData.heart)) {
				this.$Message.warning('请输入心率有效数字');
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
